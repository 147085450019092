<script>
import { OPEN_BUY_STATUSES } from "../OpenBuyStatuses";
import CompleteButton from "./BuyDialogActionButtonComplete";
import StartSortingButton from "./StartSortingButton.vue";

export default {
  components: {
    CompleteButton,
    StartSortingButton,
  },
  props: {
    buy: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    buyCheckedIn() {
      return this.buy.openBuyStatus === OPEN_BUY_STATUSES.CHECKED_IN;
    },
  },
};
</script>

<template>
  <StartSortingButton
    v-if="buyCheckedIn"
    :buy-id="buy.id"
    v-bind="$attrs"
    button-text="Start sorting"
    text
  />
  <CompleteButton v-else :buy-id="buy.id" v-bind="$attrs" />
</template>
