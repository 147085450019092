<script>
import VxDialogView from "@/components/vx/VxDialogView";

import { injectActiveEmployee } from "@/mixins/employee";
import { hasSnackbarAccess } from "@/mixins/ui";

// TODO: move to a button component?
import { buildCheckinPayload as buildReprintPayload } from "@/utils/buy";

import CompleteButton from "./components/BuyDialogActionButtonComplete";

import BuyDetailToolbarTitle from "./components/BuyDetailToolbarTitle";
import BuyDetailToolbarExtension from "./components/BuyDetailToolbarExtension";

import gql from "graphql-tag";

import { mapGetters } from "vuex";

// TODO: remove
import { hasVModel } from "@/mixins/vmodel";

import BuyDialogOpenContent from "./components/BuyDialogOpenContent";
import BuyDialogCompleteContent from "./components/BuyDialogCompleteContent";
import BuyDialogClosedContent from "./components/BuyDialogClosedContent";

import BuyDialogMessaging from "./components/BuyDialogMessaging";
import BuyTimeline from "./components/BuyTimeline";
import OpenBuyActionButton from "./components/BuyDialogOpenActionButton.vue";
import { OPEN_BUY_STATUSES } from "./OpenBuyStatuses";

const reprintQuery = gql`
  query REPRINT_BUY_QUERY($id: ID!) {
    buy(id: $id) {
      id
      containerNum
      containerDescription
      signatureUrl
      checkedInAt
      transId
      checkinEmployee {
        drsEmployeeCode
      }
      customer {
        id
        drsCustomerCode
        firstName
        lastName
        phoneNumber
        email
        loyalty
      }
    }
  }
`;

const BUY_DIALOG_QUERY = gql`
  query BUY_DIALOG_BUY_QUERY($id: ID!) {
    buy(id: $id) {
      id
      transId
      checkedInAt
      containerNum
      containerDescription
      customerId
      status
      sorterId
      signatureUrl
      customer {
        id
        drsCustomerCode
        firstName
        lastName
        phoneNumber
        email
        loyalty
        fullName
        flagged
        flagReason
      }
      openBuyStatus
    }
  }
`;
export default {
  name: "BuyDetailView",
  components: {
    VxDialogView,
    BuyDialogMessaging,
    BuyTimeline,

    BuyDialogOpenContent,
    BuyDialogCompleteContent,
    BuyDialogClosedContent,

    OpenBuyActionButton,

    BuyDetailToolbarTitle,
    BuyDetailToolbarExtension,
  },
  mixins: [hasVModel, hasSnackbarAccess, injectActiveEmployee],
  props: {
    buyId: {
      type: String,
      default: "",
    },
    customerRouteName: {
      type: String,
      required: true,
    },
    storeId: {
      type: [String, Number],
      default: undefined,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },

  // TODO: code smell, fix and remove
  provide() {
    return {
      // Allow forms to register themselves,
      // so we have easy access to their validation
      registerBuyDialogForm: this.registerForm,
      // Allow hiding the dialog from descendants
      hideDialog: () => {
        this.$router.go(-1);
      },
      // Computed validity state for descendants
      formsValidState: () => this.formsValidState,
      // Grants descendants access to dialog v-model
      // so they can handle reset when value changes
      dialogModel: () => this.localValue,
    };
  },

  data: () => ({
    // These 3 forms are acutally registered in the meta, customer and offer form components themselves.
    // Otherwise we can't be reactive to changes in them.
    customerForm: undefined,
    metaForm: undefined,
    offerForm: undefined,

    watchedQuery: undefined,
    customerLoading: false,
    openContentLoading: false,
    completeContentLoading: false,
    closedContentLoading: false,
    metaLoading: false,
    offerLoading: false,
    tab: "buy",
    showEdit: false,
  }),
  computed: {
    ...mapGetters("sockets", ["storeChannelName"]),

    // Cannot reprint slips for a buy that has not been checked in
    reprintDisabled() {
      return (
        !this.buy ||
        !["open", "complete", "totag", "closed"].includes(this.buy.status)
      );
    },

    completeDisabled() {
      if (!this.offerForm) {
        return true;
      }

      let isValid = true;

      isValid = isValid && this.offerForm.isValid;

      return !isValid;
    },
    actionDisabled() {
      if (this.anyLoading) {
        return true;
      }

      if (!this.buy) {
        return true;
      }

      // Can't action a buy that is not open
      if (this.buy.status !== "open") {
        return true;
      }

      // should always be able to start sorting
      if (this.buy.openBuyStatus === OPEN_BUY_STATUSES.CHECKED_IN) {
        return false;
      }

      return this.completeDisabled;
    },

    // temporary workaround while moving to routed dialog
    // routeBuyId was used by BuyLog report
    routeBuyId: {
      get() {
        return this.buyId;
      },
      set(v) {
        this.buyId = v;
      },
    },

    // anyLoading - returns true if any form have requests in flight
    anyLoading() {
      return [
        this.openContentLoading,
        this.completeContentLoading,
        this.closedContentLoading,

        this.customerLoading,
        this.metaLoading,
        this.offerLoading,
      ].some((e) => e);
    },
    formsValidState() {
      const customerForm = this.customerForm;
      const metaForm = this.metaForm;
      return {
        customerForm: customerForm && customerForm.isValid,
        metaForm: metaForm && metaForm.isValid,
      };
    },
    computedValue() {
      return !!this.routeBuyId;
    },
    computedBuyId() {
      return this.routeBuyId || this.buyId;
    },
    shouldShowActionButton() {
      return this.tab === "buy" && this.buy && !this.hideActions;
    },
    showActionButton() {
      return (
        this.shouldShowActionButton && !!this.buy && this.buy.status === "open"
      );
    },

    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  // TODO - see if both beforeRouteEnter and beforeRouteUpdate are needed
  beforeRouteEnter(to, from, next) {
    // TODO - try to get this from props
    next((vm) => {
      if (
        to.query &&
        to.query.buyDetailTab &&
        to.query.buyDetailTab !== vm.tab
      ) {
        vm.tab = to.query.buyDetailTab;
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    // TODO - try to get this from props
    if (
      to.query &&
      to.query.buyDetailTab &&
      to.query.buyDetailTab !== this.tab
    ) {
      this.tab = to.query.buyDetailTab;
    }

    next();
  },

  watch: {
    routeBuyId(v) {
      if (!v) return;

      this.buyId = v;
    },
  },
  apollo: {
    buy: {
      query: BUY_DIALOG_QUERY,
      variables() {
        return {
          id: this.computedBuyId,
        };
      },
      skip() {
        return !this.computedBuyId;
      },
    },
  },
  methods: {
    // TODO: fix
    // `refs` are not reactive. Since the `checkin` button
    // relies on a descendant's computed property, we "register"
    // that descendant once it's mounted. That way, we have
    // direct access to its computed `isValid` property.
    registerForm(form, vm) {
      this[form] = vm;
    },

    // TODO: fix and remove?
    handleEdit() {
      [this.$refs.customer, this.$refs.meta, this.$refs.offer].map(
        (vm) => vm && vm.showEdit && vm.showEdit()
      );

      this.showEdit = true;
      this.changeTab("buy");
    },

    // Reprint buy slips
    async handleReprint() {
      // Guard
      if (!this.$raiPos) {
        return this.showSnackbar({
          text: `Buy slips can only be reprinted from a register or tablet running ResaleAI POS`,
        });
      }

      // Query what we need to reprint the buy
      const {
        data: { buy },
      } = await this.$apollo.query({
        query: reprintQuery,
        variables: { id: this.computedBuyId },
        fetchPolicy: "network-only",
      });

      // Set checkedInByEmployeeCode
      buy.checkedInByEmployeeCode = buy.checkinEmployee.drsEmployeeCode;

      // Build the payloads for reprinting
      const { buy: buyJson, customer: customerJson } = buildReprintPayload(buy);

      // As long as the data's good, send it on to the RaiExtension
      if (buyJson && customerJson) {
        try {
          this.$raiPos.printReceipt(customerJson, buyJson);
          this.showSnackbar({
            text: `Reprinting slips for buy ${buy.transId || buy.id}.`,
          });
        } catch (error) {
          // If an error is thrown, a POS is not available on the
          // pusher channel to proxy *to*.
          // We might want to show a dialog here to choose a printer.
          this.showSnackbar({
            text: `Error printing slips for buy: ${error}`,
          });
        }
      }
    },

    handleViewCustomer() {
      const customerRoute = {
        name: this.customerRouteName,
        params: {
          customerId: this.buy.customerId,
        },
        preserveQuery: true,
      };

      this.$router.replace(customerRoute);
    },

    updateCustomerField(field, value, finishedCallback) {
      const variables = { input: { id: this.buy.customerId, [field]: value } };

      return this.$apollo
        .mutate({
          mutation: gql`
            mutation UPDATE_CUSTOMER_INFO(
              $input: UpdateCustomerInfoInputObject!
            ) {
              updateCustomerInfo(input: $input) {
                customer {
                  id
                  firstName
                  lastName
                  fullName
                  phoneNumber
                  email
                  customerId: id
                }
                errors
              }
            }
          `,
          variables,
        })
        .then(() => finishedCallback())
        .catch((error) => {
          finishedCallback();
          this.showSnackbar({
            text: `Error occurred while updating employee. ${error}`,
          });
        });
    },

    updateBuyField(field, value, finishedCallback) {
      const variables = { input: { id: this.buyId, [field]: value } };

      return this.$apollo
        .mutate({
          mutation: gql`
            mutation BUY_DIALOG_META_FORM_UPDATE_BUY(
              $input: UpdateBuyInputObject!
            ) {
              updateBuy(input: $input) {
                buy {
                  id
                  containerNum
                  containerDescription
                  estimatedPickupAt
                  buyId: id
                }
                errors
              }
            }
          `,
          variables,
          context: {
            // this (SHOULD) prevent the query being deduped
            // which means that requerying on reconnection works
            queryDeduplication: false,
          },
        })
        .then(() => finishedCallback())
        .catch((error) => {
          finishedCallback();
          this.showSnackbar({
            text: `Error occurred while updating employee. ${error}`,
          });
        });
    },

    changeTab(tabName) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          buyDetailTab: tabName,
        },
        preserveQuery: true,
      });
    },
  },
};
</script>

<template>
  <VxDialogView
    :retain-focus="false"
    :v-size="'large'"
    :h-size="'medium'"
    :error-toolbar="buy && buy.customer && buy.customer.flagged"
  >
    <template v-if="!isSmallScreen" #actions>
      <!-- if (this.buy.status === "pending") return "CheckinButton";
      // there used to be a condition for showing the sorter button.
      // should there be a default blank button? reload button?
      if (this.buy.status === "open") return "CompleteButton"; -->

      <OpenBuyActionButton
        v-if="showActionButton"
        color="primary"
        :buy="buy"
        :buy-id="buy.id"
        :disabled="actionDisabled"
      />
    </template>

    <template v-if="isSmallScreen && showActionButton" #large-actions>
      <OpenBuyActionButton
        v-if="showActionButton"
        :buy="buy"
        :buy-id="buy.id"
        :disabled="actionDisabled"
      />
    </template>

    <template #toolbar-title>
      <BuyDetailToolbarTitle
        v-bind="{
          ...buy,
          reprintDisabled: reprintDisabled,
          fullName: buy && buy.customer ? buy.customer.fullName : undefined,
          phoneNumber:
            buy && buy.customer ? buy.customer.phoneNumber : undefined,
        }"
        @viewCustomer="handleViewCustomer"
        @reprintSlips="handleReprint"
        @edit="handleEdit"
      />
    </template>

    <template #toolbar-extension>
      <BuyDetailToolbarExtension
        v-bind="{ ...buy, tab: tab }"
        @changeTab="changeTab"
      />
    </template>

    <template>
      <v-tabs-items
        v-if="buy"
        v-model="tab"
        grow
        slider-color="secondary"
        touchless
        class="BuyDetail__TabItems"
      >
        <!-- BuyMeta+BuyOffer Tab -->
        <v-tab-item value="buy" class="fill-height">
          <div v-if="buy.status === 'open'">
            <BuyDialogOpenContent
              :show-edit="showEdit"
              :store-id="storeId"
              :customer-id="(buy && buy.customerId) || ''"
              :buy-status="buy.status"
              :buy-id="buy.id"
              @loading="(v) => (openContentLoading = v)"
              @update:customerField="updateCustomerField"
              @update:buyField="updateBuyField"
            />
          </div>
          <div v-if="buy.status === 'complete'">
            <BuyDialogCompleteContent
              :show-edit="showEdit"
              :store-id="storeId"
              :customer-id="(buy && buy.customerId) || ''"
              :buy-status="buy.status"
              :buy-id="buy.id"
              @loading="(v) => (completeContentLoading = v)"
            />
          </div>
          <div v-if="buy.status === 'closed'">
            <BuyDialogClosedContent
              :show-edit="showEdit"
              :store-id="storeId"
              :customer-id="(buy && buy.customerId) || ''"
              :buy-status="buy.status"
              :buy-id="buy.id"
              @loading="(v) => (completeContentLoading = v)"
            />
          </div>
        </v-tab-item>
        <!-- Timeline Tab -->
        <v-tab-item value="timeline" class="px-3 fill-height">
          <BuyTimeline :buy-id="computedBuyId" />
        </v-tab-item>
        <!-- Messaging Tab -->
        <v-tab-item value="message" class="fill-height">
          <BuyDialogMessaging
            :customer-id="buy.customerId || undefined"
            class="fill-height"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </VxDialogView>
</template>

<style scoped lang="scss">
::v-deep .number-name-wrapper {
  overflow: hidden;

  .name-wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.v-tabs-items {
  overflow: visible;
}
</style>

<style lang="scss">
.BuyDetail__TabItems {
  height: 100%;
}
</style>
