<script>
// * `buy` prop, so mutation can happen directly in this component
// * apollo for the employee
// ---------
// > on load
// - if value present,
//    |- if buy is open, and disabled is false, show employee selector with the selected employee and a stopwatch
//    |- if buy.status >= open, show employee
// - else, show a "start sorting button"
// ---------
// > on "start sorting button" is pressed
//    - if teammode, use the active employee
//      |
//      |- emit(input, activeEmployee)
//    - else, show an employee select. only clocked in employees should show
//      |
//      |- emit(input, selectedEmployee)
import SorterSelectorBase from "./Base";
import EmployeeAvatar from "@/components/employees/Avatar";
import EmployeeSelect from "@/components/util/RaiEmployeeSelect";
import Stopwatch from "@/components/util/Stopwatch";

import { format } from "date-fns";
import { get } from "lodash";
import DoneSortingButton from "../DoneSortingButton.vue";

export default {
  name: "SorterSelectorDesktop",
  components: {
    EmployeeAvatar,
    EmployeeSelect,
    Stopwatch,
    DoneSortingButton,
  },
  extends: SorterSelectorBase,
  methods: {
    format,
    get,
  },
};
</script>

<template>
  <div class="d-flex align-center">
    <!-- employee avatar and timer -->
    <template v-if="!hideAvatar">
      <EmployeeSelect
        v-if="isEditable"
        :loading="loading"
        :value="employee"
        :employees="clockedInEmployees"
        size="32"
        @input="changeSorter"
      />
      <EmployeeAvatar
        v-else
        tooltip
        :employee-id="employee.id"
        size="32"
        class="mr-2"
      />
    </template>
    <div :class="sorterTextClasses">Sorter</div>
    <DoneSortingButton
      v-if="canComplete"
      :buy-id="buyId"
      text
      class="ml-2"
      small
    />
    <Stopwatch
      v-if="!hideStopwatch"
      class="ml-2 rai-data"
      :start="buy.startedAt"
    />
    <div v-if="!hideStopwatch" class="text-caption ml-3 grey--text">
      Due
      {{
        buy.estimatedPickupAt
          ? $filters.dateTime.relativeTime(buy.estimatedPickupAt)
          : "date not defined"
      }}
    </div>
    <!-- <span class="ml-2 rai-data" v-text="get(buy, 'sortTime')" /> -->
  </div>
</template>
