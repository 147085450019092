<script>
import Stopwatch from "@/components/util/Stopwatch.vue";

export default {
  components: {
    Stopwatch,
  },
  props: {
    buy: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
<template>
  <div class="d-flex align-center sorter-and-items">
    <span class="font-weight-bold">Total wait</span>
    <Stopwatch
      class="ml-2 rai-data font-weight-bold"
      :start="buy.checkedInAt"
    />
    <div class="ml-3 grey--text">
      Due
      {{
        buy.estimatedPickupAt
          ? $filters.dateTime.relativeTime(buy.estimatedPickupAt)
          : "date not defined"
      }}
    </div>
  </div>
</template>
