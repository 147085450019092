import get from "lodash/get";
import { hasSnackbarAccess } from "@/mixins/ui";
import {
  injectActiveEmployee,
  injectClockedInEmployees,
} from "@/mixins/employee";
import gql from "graphql-tag";

const buyQuery = gql`
  query SORTER_SELECTOR_BUY($id: ID!) {
    buy(id: $id) {
      id
      sorterId
      status
      startedAt
      sortTime
      estimatedPickupAt
    }
  }
`;

const employeeQuery = gql`
  query SORTER_SELECTOR_EMPLOYEE($id: ID!) {
    employee(id: $id) {
      id
      fullName
      avatarUrl
    }
  }
`;

export default {
  mixins: [hasSnackbarAccess, injectActiveEmployee, injectClockedInEmployees],

  props: {
    buyId: {
      type: String,
    },
    // Serialized datetime
    startedAt: {
      type: String,
      default: undefined,
    },
    // Controls `disabled` on the `START SORTING` button and the employee select
    disabled: {
      type: Boolean,
      default: false,
    },
    // Controls whether the avatar is an employee select vs just an avatar
    edit: {
      type: Boolean,
      default: false,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "accent",
    },
    text: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Start sorting",
    },
    hideStopwatch: {
      type: Boolean,
      default: false,
    },
    canComplete: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: false,
    employee: {},
    buy: {},
  }),

  apollo: {
    buy: {
      query: buyQuery,
      variables() {
        return {
          id: this.buyId,
        };
      },
      skip() {
        return !this.buyId;
      },
    },
    employee: {
      query: employeeQuery,
      variables() {
        return {
          id: get(this, "buy.sorterId"),
        };
      },
      skip() {
        return !get(this, "buy.sorterId");
      },
    },
  },

  computed: {
    isEditable() {
      return this.edit && get(this, "buy.status") === "open" && !this.disabled;
    },
    sorterTextClasses() {
      return {
        "text--primary": this.isEditable,
        "text--disabled": !this.isEditable,
      };
    },
  },

  methods: {
    // Update just the `sorterId` on a buy
    async changeSorter(employee) {
      this.loading = true;
      try {
        const {
          data: { errors },
        } = await this.$apollo.mutate({
          mutation: gql`
            mutation CHANGE_SORTER($input: UpdateBuyInputObject!) {
              updateBuy(input: $input) {
                buy {
                  id
                  sorterId
                }
                errors
              }
            }
          `,
          variables: { input: { id: this.buyId, sorterId: employee.id } },
        });
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while setting the buy sorter: ${error}`,
        });
      }
      this.loading = false;
    },
  },
};
