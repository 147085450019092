<script>
import { hasSnackbarAccess } from "@/mixins/ui";
import FINISH_SORTING from "../graphql/FinishSorting.gql";
import VxBtn from "@/core-ui/src/components/vx/VxBtn/VxBtn.vue";
export default {
  name: "DoneSortingButton",
  components: { VxBtn },
  mixins: [hasSnackbarAccess],
  props: {
    buyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      loading: false,
    };
  },
  methods: {
    handleDone() {
      this.finishSorting();
    },
    async finishSorting() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: FINISH_SORTING,
          variables: {
            id: this.buyId,
          },
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while completing sorting: ${error}`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <VxBtn :loading="loading" v-bind="$attrs" @click.stop="handleDone">
    Done
  </VxBtn>
</template>
