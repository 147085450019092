<script>
import gql from "graphql-tag";
import EmployeeAvatar from "@/components/employees/Avatar";
import { get, filter, groupBy } from "lodash";

import { format, endOfDay, parseISO } from "date-fns";
import { bizDay } from "@/utils/datetime";

const query = gql`
  query BUY_TIMELINE($id: ID!) {
    buy(id: $id) {
      id
      status
      checkedInAt
      checkedInByEmployeeId
      startedAt
      startedByEmployeeId: sorterId
      stopSortingByEmployeeId: sorterId
      completedAt
      completedByEmployeeId
      pickupAt: estimatedPickupAt
      closedAt
      closedByEmployeeId
      taggedAt
      taggedByEmployeeId
      signatureAt: signatureUpdatedAt
      signatureUrl
      startEnteringByEmployeeId: entererId
      startEnteringAt
      stopSortingAt
    }
  }
`;

const EMPLOYEE_NAME = gql`
  query EMPLOYEE_QUERY($id: ID!) {
    employee(id: $id) {
      id
      fullName
      firstName
      drsEmployeeCode
    }
  }
`;

const keyMap = {
  checkedIn: "checked in",
  signature: "digital signature on file",
  started: "sorting started",
  stopSorting: "sorting finished",
  startEntering: "entering started",
  pickup: "quoted time",
  completed: "completed",
  closed: "cashed out",
};

const getSignatureDescription = (_key, buy) => {
  if (!buy || !buy.signatureUrl) return "no digital signature on file";

  return "digital signature on file";
};

const descriptionItself = (key) => {
  return keyMap[key];
};

const getCheckedInDescription = (_key, employee) => {
  const name = employee ? employee.firstName : "unknown";

  return `checked in by ${name}`;
};

const getSortingStartedDescription = (_key, employee) => {
  const name = employee ? employee.firstName : "unknown";

  return `sorting started by ${name}`;
};

const getCompletedDescription = (_key, employee) => {
  const name = employee ? employee.firstName : "unknown";

  return `completed by ${name}`;
};

const getEnteringDescription = (_key, employee) => {
  const name = employee ? employee.firstName : "unknown";

  return `entering started by ${name}`;
};

const getSortingStoppedDescription = (_key, employee) => {
  const name = employee ? employee.firstName : "unknown";

  return `sorting finished by ${name}`;
};

const keys = (() => {
  return Object.keys(keyMap).reduce((acc, key) => {
    acc[key] = {
      desc:
        (key === "signature" && getSignatureDescription) ||
        (key === "checkedIn" && getCheckedInDescription) ||
        (key === "started" && getSortingStartedDescription) ||
        (key === "completed" && getCompletedDescription) ||
        (key === "startEntering" && getEnteringDescription) ||
        (key === "stopSorting" && getSortingStoppedDescription) ||
        descriptionItself,
      type: (["signature", "pickup"].includes(key) && "info") || undefined,
      icon:
        (key === "signature" && "$vuetify.icons.signature") ||
        (key === "pickup" && "$vuetify.icons.clock") ||
        undefined,
    };
    return acc;
  }, {});
})();

export default {
  name: "BuyTimeline",
  components: {
    EmployeeAvatar,
  },

  props: {
    showEmpty: {
      type: Boolean,
      default: false,
    },
    buyId: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    loading: false,
  }),
  computed: {
    // Transforms [String] -> [{ key: String, at: Date, by: String }]
    events() {
      let timelineItems = Object.keys(keys).map((key) => ({
        key,
        atRaw: get(this.buy, `${key}At`),
        atDisplay: this.timeDisplay(get(this.buy, `${key}At`)),
        by: get(this.buy, `${key}ByEmployeeId`),
        desc:
          (key === "checkedIn" &&
            keys[key].desc(key, this.checkInByEmployee)) ||
          ((key === "started" || key === "stopSorting") &&
            keys[key].desc(key, this.startedByEmployee)) ||
          (key === "completed" &&
            keys[key].desc(key, this.completedByEmployee)) ||
          (key === "startEntering" &&
            keys[key].desc(key, this.enteringEmployee)) ||
          keys[key].desc(key, this.buy),
        type: keys[key].type,
        icon: keys[key].icon,
      }));

      if (!this.showEmpty) {
        timelineItems = filter(timelineItems, (item) => {
          return !!item.atRaw;
        });
      }

      return timelineItems;
    },
    eventsGroupped() {
      const t = groupBy(this.events, (event) => {
        if (!event.atRaw) {
          return "no date";
        }
        const endOfDayDate = endOfDay(parseISO(event.atRaw));

        return bizDay(endOfDayDate);
      });
      return t;
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    timeDisplay(at) {
      if (!at) return "";

      return format(parseISO(at), "h:mm aaa");
    },
    infoClasses(event) {
      if (!event || event.type !== "info") return;

      return "ui--text text--lighten-1 font-italic font-weight-light";
    },
  },

  apollo: {
    buy: {
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      query,
      variables() {
        return { id: this.buyId };
      },
      update(response) {
        return response.buy;
      },
    },
    checkInByEmployee: {
      query: EMPLOYEE_NAME,
      variables() {
        return { id: !!this.buy ? this.buy.checkedInByEmployeeId : undefined };
      },
      fetchPolicy: "cache-only",
      skip() {
        return !this.buy || !this.buy.checkedInByEmployeeId;
      },
      update(response) {
        return response.employee;
      },
    },
    startedByEmployee: {
      query: EMPLOYEE_NAME,
      variables() {
        return { id: !!this.buy ? this.buy.startedByEmployeeId : undefined };
      },
      fetchPolicy: "cache-only",
      skip() {
        return !this.buy || !this.buy.startedByEmployeeId;
      },
      update(response) {
        return response.employee;
      },
    },
    completedByEmployee: {
      query: EMPLOYEE_NAME,
      variables() {
        return { id: !!this.buy ? this.buy.completedByEmployeeId : undefined };
      },
      fetchPolicy: "cache-only",
      skip() {
        return !this.buy || !this.buy.completedByEmployeeId;
      },
      update(response) {
        return response.employee;
      },
    },
    enteringEmployee: {
      query: EMPLOYEE_NAME,
      variables() {
        return {
          id: !!this.buy ? this.buy.startEnteringByEmployeeId : undefined,
        };
      },
      fetchPolicy: "cache-only",
      skip() {
        return !this.buy || !this.buy.startEnteringByEmployeeId;
      },
      update(response) {
        return response.employee;
      },
    },
  },
};
</script>

<template>
  <v-skeleton-loader
    v-if="loading"
    type="list-item-avatar@3"
    :width="`${isSmallScreen ? '100%' : '50%'}`"
    :loading="loading"
  />
  <div v-else class="d-flex flex-column">
    <div v-for="(groupEvents, eventDay) in eventsGroupped" :key="eventDay">
      <div class="text-overline">{{ eventDay }}</div>
      <v-divider class="mb-2" />
      <div
        v-for="event of groupEvents"
        :key="event.key"
        class="d-flex align-center mb-3"
      >
        <div class="mr-2 text-no-wrap" v-text="event.atDisplay" />
        <v-avatar v-if="event.type === `info`" size="32px" class="mr-2">
          <v-icon class="" v-text="event.icon" />
        </v-avatar>
        <EmployeeAvatar
          v-else-if="event.by"
          tooltip
          class="mr-2"
          :employee-id="event.by"
          icon="$vuetify.icons.noUser"
        />

        <div :key="`${event.key}-desc`" v-text="event.desc" />
      </div>
    </div>
  </div>
</template>
